import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/administrator/ggnet-market/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/administrator/ggnet-market/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/home/administrator/ggnet-market/frontend/src/core/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProductsList"] */ "/home/administrator/ggnet-market/frontend/src/modules/products/components/query-products-list.tsx");
